import React from 'react';
import Error404 from '@/assets/images/errors/handband404.webp';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading, Icon, Text, VStack } from '@chakra-ui/react';

const ErrorComponentNotFound = () => {
  const statusCode = 404;
  const { t } = useTranslation(['error']);
  const router = useRouter();

  return (
    <Flex minH="100vh" justify="center" align="center" bg="white" p="1rem">
      <Box textAlign="center" maxW="31.25rem">
        <Box position="relative" mb="2rem">
          <Image src={Error404} alt={''} width={900} height={600} />
          <Heading
            fontSize="11.25rem"
            fontWeight="bold"
            color="gray.800"
            m="0"
            textShadow="10px 10px 20px #fff"
          >
            {statusCode}
          </Heading>
        </Box>
        <Heading fontSize="1.5rem" color="gray.800" mb="0.5rem" fontWeight="bold">
          {t(`error:status_code.${statusCode}.header`)}
        </Heading>
        <Text color="gray.600" mb="2rem">
          {t(`error:status_code.${statusCode}.message`)}
        </Text>
        <VStack spacing="1rem">
          <Button
            onClick={() => router.back()}
            bg="gray.800"
            color="white"
            _hover={{ bg: 'gray.700' }}
            leftIcon={<Icon as={HiOutlineArrowLeft} />}
          >
            {t('error:go_back')}
          </Button>
          <Text fontWeight="bold" color="black">
            {t('error:contact_support_team')}
          </Text>
        </VStack>
      </Box>
    </Flex>
  );
};

export default ErrorComponentNotFound;
