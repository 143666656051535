import React from 'react';
import Error500 from '@/assets/images/errors/handband500.webp';
import Error400 from '@/assets/images/errors/handband400.webp';
import { HiOutlineRefresh } from 'react-icons/hi';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Heading, Text, Button, VStack, Icon } from '@chakra-ui/react';
import { HttpStatusCode } from 'axios';

type ErrorComponentProps = {
  errorCodeProps: HttpStatusCode.InternalServerError | HttpStatusCode.BadRequest;
};

const ErrorComponent = ({ errorCodeProps }: ErrorComponentProps) => {
  const { t } = useTranslation(['error']);
  const router = useRouter();

  const errorImage = errorCodeProps === HttpStatusCode.InternalServerError ? Error400 : Error500;

  return (
    <Flex minH="100vh" justify="center" align="center" bg="white" p="1rem">
      <Box textAlign="center" maxW="31.25rem">
        <Box position="relative" mb="2rem">
          <Image src={errorImage} alt={''} width={900} height={600} />
          <Heading
            fontSize="11.25rem"
            fontWeight="bold"
            color="gray.800"
            m="0"
            textShadow="10px 10px 20px #fff"
          >
            {errorCodeProps}
          </Heading>
        </Box>
        <Heading fontSize="1.5rem" color="gray.800" mb="0.5rem" fontWeight="bold">
          {t(`error:status_code.${errorCodeProps}.header`)}
        </Heading>
        <Text color="gray.600" mb="2rem">
          {t(`error:status_code.${errorCodeProps}.message`)}
        </Text>
        <VStack spacing="1rem">
          <Button
            onClick={() => router.reload()}
            bg="gray.800"
            color="white"
            _hover={{ bg: 'gray.700' }}
            leftIcon={<Icon as={HiOutlineRefresh} />}
          >
            {t('error:refresh_page')}
          </Button>
        </VStack>
      </Box>
    </Flex>
  );
};

export default ErrorComponent;
