import * as Sentry from '@sentry/nextjs';
import type { NextPageContext } from 'next';
import Error from 'next/error';

import ErrorComponent from '@/components/errors/ErrorComponent';
import ErrorComponentNotFound from '@/components/errors/ErrorComponentNotFound';
import { HttpStatusCode } from 'axios';
import type { JSX } from 'react';

type ErrorMapping = {
  [key in HttpStatusCode]: JSX.Element;
};

const errorMapping: Partial<ErrorMapping> = {
  [HttpStatusCode.BadRequest]: <ErrorComponent errorCodeProps={HttpStatusCode.BadRequest} />,
  [HttpStatusCode.NotFound]: <ErrorComponentNotFound />,
  [HttpStatusCode.InternalServerError]: (
    <ErrorComponent errorCodeProps={HttpStatusCode.InternalServerError} />
  ),
};

const getErrorComponent = (statusCode: number): JSX.Element => {
  if (statusCode >= 400 && statusCode < 500 && statusCode != HttpStatusCode.NotFound) {
    return errorMapping[HttpStatusCode.BadRequest]!;
  }
  if (statusCode === HttpStatusCode.NotFound) {
    return errorMapping[HttpStatusCode.NotFound]!;
  }
  return errorMapping[HttpStatusCode.InternalServerError]!;
};

const CustomErrorComponent = ({ statusCode }: { statusCode: number }) => {
  return getErrorComponent(statusCode);
};

CustomErrorComponent.getInitialProps = async (contextData: NextPageContext) => {
  // In case this is running in a serverless function, await this in order to give Sentry
  // time to send the error before the lambda exits
  await Sentry.captureUnderscoreErrorException(contextData);

  // This will contain the status code of the response
  return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
